import React from "react"
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';

import Bookmarks from "../../data/bookmarks"

class BookmarkToggle extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isBookmarked: false,
    };
  }

  componentDidMount() {
    this.setState({
      isBookmarked: Bookmarks.isBookmarked(this.props.bookmarkType, this.props.itemId),
    });
  }

  render() {
    const buttonText = !this.state.isBookmarked ? "Add to Bookmarks" : "Remove from Bookmarks"
    const icon = !this.state.isBookmarked ? <BookmarkBorderIcon/> : <BookmarkIcon/>;
    const bookmarkType = this.props.bookmarkType;
    const onBookmarkChanged = this.props.bookmarkChanged;
    const clickHandler = () => {
      if (this.state.isBookmarked) {
        Bookmarks.removeBookmarkedItem(bookmarkType, this.props.itemId);
        this.setState({isBookmarked: false});
      }
      else
      {
        Bookmarks.addBookmarkedItem(bookmarkType, this.props.itemId);
        this.setState({isBookmarked: true});
      }

      if (onBookmarkChanged) {
        onBookmarkChanged();
      }
    };

    var button = null;
    if (this.props.small) {
      button = <IconButton color="primary" aria-label={buttonText} onClick={clickHandler}>{icon}</IconButton>
    }
    else
    {
      button = <Button variant="contained" color="primary" onClick={clickHandler}>{icon}{buttonText}</Button>
    }

    return button;
  }
}

export default BookmarkToggle;