/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

import Header from "./header"
import AppTabs from "./app-tabs"
import "./layout.css"

import Catalog from '../services/catalog';
let testVar = 0;
class Layout extends PureComponent {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    Catalog.init();
    console.log('JSchlechte; Initializing the Layout component', testVar++);
  }

  render() {
    const { navTab, children } = this.props;
    return <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Header siteTitle={data.site.siteMetadata.title}/>
        <AppTabs navTab={navTab}/>
        <div
          style={{
            margin: `0 auto`,
            maxWidth: 960,
            padding: `0px 1.0875rem 1.45rem`,
            paddingTop: `1.45rem`,
          }}
        >
          <main>{children}</main>
          <footer>
            © {new Date().getFullYear()} <a href="https://woodenanchorgames.com/">Wooden Anchor Games</a>
          </footer>
        </div>
      </>
    )}
  />
  }
}

// const Layout = ({ navTab, children }) => (
//   <StaticQuery
//     query={graphql`
//       query SiteTitleQuery {
//         site {
//           siteMetadata {
//             title
//           }
//         }
//       }
//     `}
//     render={data => (
//       <>
//         <Header siteTitle={data.site.siteMetadata.title}/>
//         <AppTabs navTab={navTab}/>
//         <div
//           style={{
//             margin: `0 auto`,
//             maxWidth: 960,
//             padding: `0px 1.0875rem 1.45rem`,
//             paddingTop: `1.45rem`,
//           }}
//         >
//           <main>{children}</main>
//           <footer>
//             © {new Date().getFullYear()} <a href="https://woodenanchorgames.com/">Wooden Anchor Games</a>
//           </footer>
//         </div>
//       </>
//     )}
//   />
// )

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
