import fetch from 'unfetch';

class Catalog {
  constructor() {
    this.initPromise = null;
    this.data = null;

    this.init = this.init.bind(this);
    this.getVersion = this.getVersion.bind(this);
    this.getPartById = this.getPartById.bind(this);
    this.getMachineById = this.getMachineById.bind(this);
    this.getControlPanelById = this.getControlPanelById.bind(this);
  }

  async init() {
    if (!this.initPromise) {
      this.initPromise = fetch('/catalog.json')
        .then(r => r.json())
        .then(obj => this.data = obj)
        .catch(e => console.error(e));
    }
    return this.initPromise;
  }

  async getVersion() {
    await this.init();
    return this.data.version;
  }

  async getParts() {
    await this.init();
    return this.data.parts;
  }

  async getPartById(id) {
    await this.init();
    return this.data.parts.find(p => p.id === id);
  }

  async getMachines() {
    await this.init();
    return this.data.machines;
  }

  async getMachineById(id) {
    await this.init();
    return this.data.machines.find(p => p.id === id);
  }
  
  async getControlPanels() {
    await this.init();
    return this.data.controlPanels;
  }
  
  async getControlPanelById(id) {
    await this.init();
    return this.data.controlPanels.find(p => p.id === id);
  }

  async getMfgById(id) {
    await this.init();
    return this.data.manufacturers.find(p => p.id === id);
  }
}

export default new Catalog();