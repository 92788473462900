import React from "react"
import { Grid, Row, Col } from 'react-flexbox-grid';
import { Link } from "gatsby"
import ReactMarkdown from 'react-markdown';

import Layout from "../components/layout"
import SEO from "../components/seo"
import Catalog from "../services/catalog"
import ManPages from "../services/man-pages"
import SpecSet from "../components/spec/spec-set"
import {PartList} from "../components/items/item-list"
import BookmarkToggle from "../components/bookmarks/bookmark-toggle"


class ViewMachinePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      machine: null,
      factoryParts: [],
      mfg: null,
      page: null,
    }

    this.init = this.init.bind(this);
   }

  componentWillMount() {
    this.init();
  }

  async init() {
    const hash = this.props.location.hash;
    const id = hash ? hash.substr(1) : null;

    Catalog.getMachineById(id)
      .then(machine => {
        this.setState({machine});

        Promise.all(machine.mfgPartIds.map(Catalog.getPartById))
          .then(factoryParts => this.setState({factoryParts}))
          .catch(console.error);

        const { mfgId } = machine;
        if (mfgId) {
          Catalog.getMfgById(mfgId)
            .then(mfg => this.setState({mfg}))
            .catch(console.error);
        }
      })
      .catch(console.error);

    ManPages.getMachinePage(id)
      .then(page => this.setState({page}))
      .catch(console.error);
  }

  render() {
    const { machine, factoryParts, page, mfg } = this.state;
    if (!machine) return null;

   // TODO: Pull this out to make generic.
   const defaultName = 'Unknown Machine';
   const bookmarkType = 'machines';

    const { body, profileImg } = page || {};
    const { id, sn, type, name = defaultName, minSpec } = machine;

    return(
      <Layout navTab={1}>
        {/* TODO: Extract out into ItemHeader. */}
        <SEO title={name} />
        <header>
          <h1 style={{margin:0}}>{name}</h1>
          <div>Serial #: {sn} </div>
        </header>
        <Grid fluid>
          <Row>
            <Col xs={12} md={6}>
              {/* TODO: Extract to ItemProfileImg. */}
              <div style={{
                backgroundColor: "Gray",
                backgroundImage: profileImg ? `url(${profileImg})` : undefined,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                paddingTop: '100%',
                }}>
              </div>
            </Col>
            <Col xs={12} md={6}>
              {/* TODO: Shuffle this around in the Layout, then pull into something shared. */}
              <div>Type: {type}</div>
              <div>Mfg: {mfg && mfg.name || 'Unknown'}</div>
              <BookmarkToggle bookmarkType={bookmarkType} itemId={id}/>
            </Col>
          </Row>
        </Grid>
        <h2>Min Specifications</h2>
        <SpecSet spec={minSpec}/>
        <h2>Factory Parts</h2>
        <PartList items={factoryParts}/>
        { body ? <ReactMarkdown source={body} /> : null}
        <h2>Boot Instructions</h2>
        <p>Identify the <Link to="/browse-controls/">control panel</Link> and activate to boot.</p>
      </Layout>
    );
  }
}

export default ViewMachinePage