import React from "react"
import MediaQuery from 'react-responsive'
import Tooltip from '@material-ui/core/Tooltip';

const SpecStat = ({statName, icon, value}) => (
  <Tooltip disableFocusListener title={statName + " Points"}>
    <div style={{display: 'flex', padding: '4px', backgroundColor: 'darkGray', borderRadius: '32px'}}>
      {/* <div style={{backgroundImage: `url(${require("../../images/spec/Compute.png")}`, backgroundSize: 'cover', width: '42px', height: '42px'}}></div> */}
      <MediaQuery maxWidth={576}>
        <img src={icon} alt={statName + " Points"} style={{maxWidth: '32px', maxHeight: '32px', flexShrink: 1, margin: 0}}/>
      </MediaQuery>
      <MediaQuery minWidth={576}>
        <img src={icon} alt={statName + " Points"} style={{maxWidth: '48px', maxHeight: '48px', flexShrink: 1, margin: 0}}/>
      </MediaQuery>
      <div style={{flexGrow: 1, margin: 'auto', fontSize: '1em', textAlign: 'center', fontFamily: 'Roboto'}}>{value}</div>
    </div>
  </Tooltip>
)

export default SpecStat
