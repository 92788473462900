import React from "react"
import ListItem from "@material-ui/core/ListItem"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"
import { Link } from "gatsby"

import BookmarkToggle from "../bookmarks/bookmark-toggle"

const ListItemLink = (props) => (<ListItem button component={Link} {...props} />);

const LineItem = (props) => (
  <ListItemLink to={props.to || "#"}>
    {props.children}
    <ListItemSecondaryAction>
      <BookmarkToggle small bookmarkType={props.bookmarkType} itemId={props.itemId} bookmarkChanged={props.bookmarkChanged}/>
    </ListItemSecondaryAction>
  </ListItemLink>
);

export default LineItem;