import React from "react"
import List from "@material-ui/core/List"

import IdentifiableCard from "./identifiable-card"
import SpecSet from "../spec/spec-set"
import LineItem from "./line-item"

class PartList extends React.Component {
  render() {
    const list = this.props.items.map(p => (
      <LineItem key={p.id} to={`/view-part/#${p.id}`} bookmarkType='parts' itemId={p.id} bookmarkChanged={this.props.bookmarkChanged}>
        <IdentifiableCard item={p}>
          <SpecSet spec={p.spec}/>
        </IdentifiableCard>
      </LineItem>
    ));

    return(
      <List>
        {list}
      </List>
    );
  }
}

class MachineList extends React.Component {
  render() {
    const list = this.props.items.map(m => (
      <LineItem key={m.id} to={`/view-machine/#${m.id}`} bookmarkType='machines' itemId={m.id} bookmarkChanged={this.props.bookmarkChanged}>
        <IdentifiableCard item={m}>
          <div>Machine Type: {m.type}</div>
          <SpecSet spec={m.minSpec}/>
        </IdentifiableCard>
      </LineItem>
    ));

    return(
      <List>
        {list}
      </List>
    );
  }
}

class ControlList extends React.Component {
  render() {
    const list = this.props.items.map(c => (
      <LineItem key={c.id} to={`/view-control/#${c.id}`} bookmarkType='controls' itemId={c.id} bookmarkChanged={this.props.bookmarkChanged}>
        <IdentifiableCard item={c}/>
      </LineItem>
    ));

    return(
      <List>
        {list}
      </List>
    );
  }
}

export {
  PartList,
  MachineList,
  ControlList
}