import React from "react"
import { Grid, Row, Col } from 'react-flexbox-grid';

import SpecStat from "./spec-stat"
import ThermalIcon from "../../images/spec/Thermal.png"
import ComputeIcon from "../../images/spec/Compute.png"
import MechanicalIcon from "../../images/spec/Mechanical.png"


class SpecSet extends React.Component {
  render() {
    const spec = this.props.spec;

    if (!spec) {
      return(
        <h1>Error No Spec</h1>
      );
    }

    return(
      <Grid fluid style={{padding: "0px"}}>
        <Row>
          <Col xs={4} md={2} style={{padding: "4px"}}>
            <SpecStat icon={ThermalIcon} statName="Thermal" value={spec.cool}/>
          </Col>
          <Col xs={4} md={2} style={{padding: "4px"}}>
            <SpecStat icon={ComputeIcon} statName="Compute" value={spec.compute}/>
          </Col>
          <Col xs={4} md={2} style={{padding: "4px"}}>
            <SpecStat icon={MechanicalIcon} statName="Mechanical" value={spec.mech}/>
          </Col>
        </Row>
      </Grid>
    );
  }
}

export default SpecSet;