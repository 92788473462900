import fetch from 'unfetch';

import Catalog from './catalog';

class ManPages {
  async _getItem(version, type, id) {
    const req = await fetch(`/${version}/${type}/${id}.json`);
    return req.json();
  }

  async _getItemLatest(type, id) {
    const version = await Catalog.getVersion() || 'v1';
    return this._getItem(version, type, id);
  }

  async getPartPage(id) {
    return await this._getItemLatest('parts', id);
  }

  async getMachinePage(id) {
    return await this._getItemLatest('machines', id);
  }

  async getControlPanelPage(id) {
    return await this._getItemLatest('control-panels', id);
  }

  async getMfgPage(id) {
    return await this._getItemLatest('manufacturers', id);
  }
}

export default new ManPages();