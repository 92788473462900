import React from 'react';
import { Link } from "gatsby"
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import Typography from '@material-ui/core/Typography';
import BookIcon from '@material-ui/icons/Book';
import BookmarksIcon from '@material-ui/icons/Bookmarks';
import KeyboardIcon from '@material-ui/icons/Keyboard'
import Icon from '@material-ui/core/Icon';
import MediaQuery from 'react-responsive'

import MachineImage from '../images/Machine.png'
import MachineBlackImage from '../images/Machine-Black.png'
import PartImage from '../images/Part.png'
import PartBlackImage from '../images/Part-Black.png'

const MachineIcon = (props) => (
  <Icon>
    <img src={props.invert ? MachineBlackImage : MachineImage} alt="Machine"/>
  </Icon>
)

const PartIcon = (props) => (
  <Icon>
    <img src={props.invert ? PartBlackImage : PartImage} alt="Part"/>
  </Icon>
)

const TabContainer = (props) => {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

function LinkTab(props) {
  return (
    <Tab
      component={Link}
      onClick={event => {
        // event.preventDefault();
      }}
      {...props}
    />
  );
}

function LinkNavAction(props) {
  return (
    <BottomNavigationAction
      component={Link}
      onClick={event => {
        // event.preventDefault();
      }}
      {...props}
    />
  );
}

class AppTabs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  render() {
    return (
      <div>
        <MediaQuery maxWidth={576}>
          <AppBar position="static" style={{position: 'fixed', bottom: 0, width: '100%'}}>
            <BottomNavigation value={this.props.navTab || 0}>
              <LinkNavAction label="Manual" to="/" icon={<BookIcon />} />
              <LinkNavAction label="Machines" to="/browse-machines" icon={<MachineIcon invert/>} />
              <LinkNavAction label="Parts" to="/browse-parts" icon={<PartIcon invert />} />
              <LinkNavAction label="Controls" to="/browse-controls" icon={<KeyboardIcon />} />
              <LinkNavAction label="Bookmarks" to="/bookmarks" icon={<BookmarksIcon/>} />
            </BottomNavigation>
          </AppBar>
        </MediaQuery>
        <MediaQuery minWidth={576}>
          <AppBar position="static">
            <Tabs variant="fullWidth" value={this.props.navTab || 0}>
              <LinkTab label="Manual" to="/" icon={<BookIcon/>}/>
              <LinkTab label="Machines" to="/browse-machines" icon={<MachineIcon/>}/>
              <LinkTab label="Parts" to="/browse-parts" icon={<PartIcon/>}/>
              <LinkTab label="Controls" to="/browse-controls" icon={<KeyboardIcon/>}/>
              <LinkTab label="Bookmarks" to="/bookmarks" icon={<BookmarksIcon/>}/>
            </Tabs>
          </AppBar>
        </MediaQuery>
      </div>
    );
  }
}

export default AppTabs
