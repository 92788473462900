import React from "react"
import { Grid, Row, Col } from 'react-flexbox-grid';

// import SpecSet from "../spec/spec-set"

class IdentifiableCard extends React.Component {
  render() {
    const item = this.props.item;

    var name = item.name || "Unknown Item";

    return(
      <Grid fluid style={{width: '100%'}}>
        <Row>
          <Col xs={12} md={8}>
            <h2>{name}</h2>
          </Col>
          <Col xs={12} md={4}>
            <div>Serial #: {item.sn}</div>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            {this.props.children}
          </Col>
        </Row>
      </Grid>
    );
  }
}

export default IdentifiableCard;