

// Internal
function _putObjArray(key, obj) {
  localStorage.setItem(key, JSON.stringify(obj));
}

// Public
function getBookmarkedItems(key) {
  var objArr = [];
  var json = localStorage.getItem(key);
  if (json) {
    var parsed = JSON.parse(json);
    if (Array.isArray(parsed)) {
      objArr = parsed;
    }
  }

  return objArr;
}

function isBookmarked(key, id) {
  return getBookmarkedItems(key).includes(id);
}

function addBookmarkedItem(key, id) {
  var arr = getBookmarkedItems(key);
  if (!arr.includes(id)) {
    arr.push(id);
    _putObjArray(key, arr)
  }
}

function removeBookmarkedItem(key, id) {
  var arr = getBookmarkedItems(key);
  const idx = arr.indexOf(id);
  if (idx >= 0) {
    arr.splice(idx, 1);
    _putObjArray(key, arr);
  }
}

function clearBookmarks(key) {
  _putObjArray(key, []);
}

export default {
  getBookmarkedItems,
  isBookmarked,
  addBookmarkedItem,
  removeBookmarkedItem,
  clearBookmarks,
};